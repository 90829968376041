import { Button, Image } from "antd";
import React from "react";
import styles from "./Header.module.css";
import { animateScroll as scroll } from "react-scroll";
import useWindowDimensions from "../hook/useWindowDimensions";

function Footer() {
  const { width } = useWindowDimensions();

  return (
    <footer>
      <div className={`${styles.getinTouch_div} contact-section`}>
        <Image
          src="./img/backgroundBg.png"
          preview={false}
          className="getinto_back"
        />
        <div className={styles.ready_card_text_div}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className={styles.ready_card_title}>Ready to Invest?</div>
            <div className={styles.ready_card_text}>
              Fill out the following form and we’ll be in touch.
            </div>
          </div>
          <a href="https://fyirw2tfakw.typeform.com/to/UX93QZNl" target='_blank' rel="noopener noreferrer">
            <Button className="getin_btn">Get in Touch</Button>
          </a>
        </div>
      </div>
      {width < 768 ? (
        <div className={styles.footer_div}>
          <div
            className={styles.footer_image_div}
            onClick={() => scroll.scrollToTop()}
          >
            <Image src="/img/logo.svg" preview={false} />
          </div>
          <div className={styles.copyright_div}>
            <div className={styles.footer_icon_div}>
              <div className={styles.twitterIcon}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/O2XCapital?t=Ba1IFFhDDjEJ0LODbK6M7g&s=31"
                >
                  <Image
                    src="./img/icontwitter.svg"
                    preview={false}
                    className="twitterIcon"
                  />
                </a>
              </div>
              <div className={styles.discordIcon}>
                <Image
                  src="./img/icondiscord.svg"
                  preview={false}
                  className="discordIcon"
                />
              </div>
            </div>
          </div>
          <div className={styles.copyright_text}>Copyright @ O2XC 2023</div>
        </div>
      ) : (
        <div className={styles.footer_div}>
          <div
            className={styles.footer_image_div}
            onClick={() => scroll.scrollToTop()}
          >
            <Image src="/img/logo.svg" alt="logo_image" preview={false} />
          </div>
          <div className={styles.copyright_text}>Copyright @ O2XC 2023</div>
          <div className={styles.copyright_div}>
            <div className={styles.footer_icon_div}>
              <div className={styles.twitterIcon}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/O2XCapital?t=Ba1IFFhDDjEJ0LODbK6M7g&s=31"
                >
                  <Image
                    src="./img/icontwitter.svg"
                    preview={false}
                    className="twitterIcon"
                  />
                </a>
              </div>
              <div className={styles.discordIcon}>
                <Image
                  src="./img/icondiscord.svg"
                  preview={false}
                  className="discordIcon"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
