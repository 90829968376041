import React from "react";
import styles from "./Header.module.css";
import { Button, Image } from "antd";

class Header extends React.Component {
  render() {
    return (
      <header>
        <div className={styles.header_div}>
          <Image src="/img/logo.svg" preview={false} />
          <div className={styles.middleDiv}>
            <a href="#" className={styles.about}>
              ABOUT
            </a>
            {/* <a href="#" className={styles.portfolio}>
              PORTFOLIO
            </a> */}
            <a href="#" className={styles.blog}>
              BLOG
            </a>
            <a href="#" className={styles.contact}>
              CONTACT
            </a>
          </div>
          <a href="https://fyirw2tfakw.typeform.com/to/UX93QZNl" rel="noopener noreferrer">
            <Button className={styles.invest_btn}>INVEST WITH US</Button>
            </a>
        </div>
      </header>
    );
  }
}

export default Header;
