import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home/Home";
import About from "./views/About";
import styles from "./App.module.css";
import { Button, Drawer, Image, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import useWindowDimensions from "../src/hook/useWindowDimensions";
import { menuIcon } from "./utils/allSvgs";
import InlineSVG from "svg-inline-react";
import { CloseOutlined } from "@ant-design/icons";
import {
  animateScroll as scroll,
  scroller,
} from "react-scroll";

function App() {
  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const { width } = useWindowDimensions();

  const renderone = () => (
    <header>
      <div className={styles.mobile_header_div}>
        <img src="/img/logo.svg" />
        <CloseOutlined
          onClick={() => setDrawerVisible(false)}
          style={{ color: "white" }}
        />
      </div>
    </header>
  );

  function scrollTo(e) {
    if (e === "about") {
      scroller.scrollTo("about-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70
      });
      setDrawerVisible(false);
    } else if (e === "Faq") {
      scroller.scrollTo("Faq-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70
      });
      setDrawerVisible(false);
    }
    if (e === "contact") {
      scroller.scrollTo("contact-section", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -70
      });
      setDrawerVisible(false);
    }
  }

  return (
    <div>
      <Router>
        <Layout style={{ backgroundColor: "#0f0f11" }}>
          {width < 768 ? (
            <header>
              <div className={styles.mobile_header_div}>
                <img src="/img/logo.svg" />
                <InlineSVG
                  src={menuIcon}
                  className="barsMenu"
                  type="primary"
                  onClick={() => setDrawerVisible(true)}
                >
                  <span className="barsBtn" />
                </InlineSVG>
              </div>
            </header>
          ) : (
            <></>
          )}
          <Content
            className={width > 767 ? styles.bg_main_div : styles.bg_main_div2}
          >
            <div className={styles.bg_container}>
              {width < 768 ? (
                <></>
              ) : (
                <header>
                  <div className={styles.header_div}>
                    <img src="/img/logo.svg" />
                    <div className={styles.middleDiv}>
                      <a
                        href="#"
                        className={styles.about}
                        onClick={() => scrollTo("about")}
                      >
                        ABOUT
                      </a>
                      {/* <a href="#" className={styles.portfolio}>
                        PORTFOLIO
                      </a> */}
                      <a
                        href="#"
                        className={styles.blog}
                        onClick={() => scrollTo("Faq")}
                      >
                        FAQ
                      </a>
                      <a
                        href="#"
                        className={styles.contact}
                        onClick={() => scrollTo("contact")}
                      >
                        CONTACT
                      </a>
                    </div>
                    <a
                      href="https://fyirw2tfakw.typeform.com/to/UX93QZNl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="invest_btn">INVEST WITH US</Button>
                    </a>
                  </div>
                </header>
              )}
              <div>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>

                  <Route path="/about">
                    <About />
                  </Route>
                </Switch>
              </div>
            </div>
          </Content>
          <Footer>
            <Footer />
          </Footer>
        </Layout>
      </Router>
      <Drawer
        title={renderone()}
        placement="left"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={isDrawerVisible}
        className="homeDrawer"
        style={{
          backgroundColor: "#0f0f11",
          display: "flex",
          margin: "auto 0",
        }}
      >
        <div>
          <div>
            <div className={styles.drawerDiv}>
              <a
                href="#"
                className={styles.drawerAbout}
                onClick={() => scrollTo("about")}
              >
                ABOUT
              </a>
              {/* <a href="#" className={styles.drawerPortfolio}>
                PORTFOLIO
              </a> */}
              <a
                href="#"
                className={styles.drawerBlog}
                onClick={() => scrollTo("Faq")}
              >
                FAQ
              </a>
              <a
                href="#"
                className={styles.drawerContact}
                onClick={() => scrollTo("contact")}
              >
                CONTACT
              </a>
            </div>
          </div>

          <div className={styles.copyright_div}>
            <div className={styles.footer_icon_div}>
              <div className={styles.twitterIcon}>
                <a
                  target="_blank"
                  href="https://twitter.com/O2XCapital?t=Ba1IFFhDDjEJ0LODbK6M7g&s=31"
                >
                  <Image src="./img/icontwitter.svg" preview={false} />
                </a>
              </div>
              <div className={styles.discordIcon}>
                <Image src="./img/icondiscord.svg" preview={false} />
              </div>
            </div>
            <div className={styles.copyright_text}>Copyright @ O2XC 2023</div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default App;
