import React from 'react'

class Header extends React.Component{
    render(){
        return (
            <main className="container mx-auto px-3 pb-16">
                <h1 className="text-3xl font-bold">About</h1>

                
            </main>
        )
    }
}

export default Header;